export const experienceDataCollection = [
  {
    startDate: "February, 2024",
    endDate: "May, 2024",
    title: "Web Developer Intern",
    details:
      "1.Worked as a MERN Stack Intern, where responsibilities included developing and maintaining web applications using MongoDB, Express.js, React, and Node.js<br/><br/>2. Collaborated with the development team to design and implement new features, optimized existing code for better performance, and participated in code reviews.<br/><br/>3. Gained hands-on experience with front-end and back-end development, RESTful APIs, and version control using Git.",
    company: "InfoClick Digital",
  },
  {
    startDate: "June , 2023",
    endDate: "August, 2023",
    title: "Tech Lead: E-Commerce project",
    details:
      "1. Worked as a tech lead for a college project. <br/></br>2. Overseeing the development and implementation of the project, coordinating tasks among team members. <br></br>3. Ensuring the timely completion of deliverables, deployment and resolving technical challenges.",
    company: "College Project",
  },
  {
    startDate: "March 2022",
    endDate: "June 2022",
    title: "PHP Developer Intern",
    details:
      "Worked on developing dynamic websites using PHP, Laravel and WordPress.",
    company: "Solution Gate Pvt. Ltd.",
  },
];

export const Youtube = [
    "https://www.youtube.com/embed/MwIr8ibZ3lo?si=YjU26k8BObORkqPu","https://www.youtube.com/embed/N0kF9LnWLiI?si=6khuHnjgz-RkHzWy",
"https://www.youtube.com/embed/BB-c__liddg?si=bX3HDaxlGn43BqWV","https://www.youtube.com/embed/tjaKBiuk2V4?si=LLFfCeRaL__wvBqX"
];